body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.myTitle {
  font-size: 65px;
  font-family: 'Poppins, sans-serif';
  text-align: center;
}

@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}


.myTitle {
  font-size: 25px; /* default size for mobile and smaller devices */
 
  background: linear-gradient(
    to right,
    #000000 20%,
    #484C5D 30%,
    #484C5D 70%,
    #000000 80%
);

-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
background-size: 500% auto;
animation: textShine 5s ease-in-out infinite alternate;
}


@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

/*mobile*/
@media (max-width:479px){
  .myTitle{
    line-height:45px;
    font-size:35px;
  }
  .mydescription{
    font-size:18px !important;
  }
  /*graphs gap*/
 /* .m-8bffd616{
    gap:100px !important;
  }*/
  .recharts-wrapper{
    margin-left:-3%;
  }
  .myGrid{
    margin-bottom:82px !important;
  }
  /*cell titles*/
  .m-4e7aa4f3{
    text-align:center !important;
    font-size:10px;
  }
  .logo-wrapper{
    display:block !important;
    text-align:center;
    margin-left:0 !important;
  }

 
}

@media (min-width: 480px) {
  .myTitle {
    font-size: 30px; /* size for larger mobile devices */
    
  }
}

@media (min-width: 768px) {
  .myTitle {
    font-size: 40px; /* size for tablets and small desktops */
  }
 
}

/*ipad specific*/
@media (min-width:768px) and (max-width:1023px){
 /*graphs*/
 .m-8bffd616{
  display:block !important;
}
.myGrid{
  width:95% !important;
  margin-bottom:85px !important;
}
.m-46b77525{
  margin-bottom:10px;
}
.m-8bffd616 button{
  width:100% !important;


}
}

@media (min-width: 1024px) {
  .myTitle {
    font-size: 65px; /* size for large desktops */
  }
}

.mydescription {
  font-size: 12px; /* default size for mobile and smaller devices */
  font-family: 'Poppins, sans-serif';
  text-align: center;
  color: grey;
}

@media (min-width: 480px) {
  .mydescription {
    font-size: 14px; /* size for larger mobile devices */
  }
}

@media (min-width: 768px) {
  .mydescription {
    font-size: 16px; /* size for tablets and small desktops */
  }
}

@media (min-width: 1024px) {
  /*.mydescription {
    font-size: 18px; /* size for large desktops 
  }*/
}

.myGrid {
  width: 100%; /* default width for mobile and smaller devices */
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .myGrid {
    width: 32%; /* width for tablets and desktops */
    
  }
}

/*Additional styling for ABNB DATA*/
/*<---------HOME PAGE------->*/
/*top bar*/
.m-3b16f56b
{
  padding-top:30px;
  padding-bottom:30px;

}
/*title to be 600 weight*/
.myTitle{
  font-weight:600;
  margin-top:0;
}
/*description*/
.mydescription{
  font-size:25px;
  font-family:'Poppins',sans-serif;
}
/*bg & padding*/
/* .main-area-home{
  background-image:url('assets/background-airbnbdata.png');
  background-size:unset;
  background-position-x: center;
  background-position-y: 100px;
  background-repeat:no-repeat;
  padding:25px;
} */
/*button on chart page*/
.m-77c9d27d{
  margin-left:0 !important;
}
/*hide profile in top right*/
.profile-button svg, .profile-button img{
 display:none;
}
/*make last month overflow visible in charts*/
.recharts-surface{
  overflow:visible;
}
/*legends wrapper*/
.recharts-legend-wrapper{
  position:relative !important;
  display:block !important;
  padding-bottom:15px !important;
}
.m-8bffd616 button{
  --button-bg:linear-gradient(90deg, #00a699 0%, #01e3d2 100%) !important;
  --button-hover:linear-gradient(90deg, #00a699 0%, #01e3d2 100%) !important;
}

